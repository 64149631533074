<script setup lang="ts">
/**
 * Tooltip to show help text
 * @displayName Tooltip
 */
import { vOnClickOutside } from '@vueuse/components';
export type Position = 'top' | 'bottom' | 'start' | 'end';
export type Background = 'light' | 'gray' | 'brown' | 'dark';
interface Props {
  /**
   * To be used to generate a unique id for the tooltip and its target
   */
  name: string;
  /**
   * The position of the tooltip
   * @default top
   * @values top, bottom, start, end
   */
  position?: Position;
  /**
   * The background of the tooltip
   * @default gray
   * @values light, gray, brown
   */
  background?: Background;
}

interface WindowPosition {
  x: number;
  y: number;
}

const props = withDefaults(defineProps<Props>(), {
  position: 'top',
  background: 'gray',
});

const tooltipId = `${props.name}-tooltip`;
const targetId = `${props.name}-target`;
const targetEl = ref<HTMLElement | null>(null);
const tooltipEl = ref<HTMLElement | null>(null);
const tooltipPosition = ref<Position>(props.position);
const visible = ref<boolean>(false);

interface BuildPositionParams {
  tooltipWidth: number;
  tooltipHeight: number;
  targetWidth: number;
  targetHeight: number;
  targetX: number;
  targetY: number;
  position: Position;
}

interface ComputedPosition {
  top: boolean;
  bottom: boolean;
  start: boolean;
  end: boolean;
}

const findAvailability = ({
  tooltipWidth,
  tooltipHeight,
  targetWidth,
  targetHeight,
  targetX,
  targetY,
}: BuildPositionParams): ComputedPosition => {
  const xEdgesClear = (targetX - (tooltipWidth / 2) + (targetWidth / 2) >= 0)
                   && (targetX + (tooltipWidth / 2) - (targetWidth / 2) <= window.innerWidth);
  const yEdgesClear = (targetY - (tooltipHeight / 2) + (targetHeight / 2) >= 0)
                   && (targetY + (tooltipHeight / 2) - (targetHeight / 2) <= window.innerHeight);

  return {
    top: targetY - tooltipHeight - 10 >= 0 && xEdgesClear,
    bottom: targetY + targetHeight + tooltipHeight + 10 <= window.innerHeight && xEdgesClear,
    start: targetX - tooltipWidth - 10 >= 0 && yEdgesClear,
    end: targetX + targetWidth + tooltipWidth + 10 <= window.innerWidth && yEdgesClear,
  };
};

const buildPosition = ({
  tooltipWidth,
  tooltipHeight,
  targetWidth,
  targetHeight,
  targetX,
  targetY,
  position,
}: BuildPositionParams): WindowPosition => {
  const MARGIN = 18;
  const xOffset = targetX - (tooltipWidth / 2) + (targetWidth / 2);
  const yOffset = targetY - (tooltipHeight / 2) + (targetHeight / 2);

  switch (position) {
    case 'top':
      return {
        x: xOffset,
        y: targetY - tooltipHeight - MARGIN,
      };
    case 'bottom':
      return {
        x: xOffset,
        y: targetY + targetHeight + MARGIN,
      };
    case 'start':
      return {
        x: targetX - tooltipWidth - MARGIN,
        y: yOffset,
      };
    case 'end':
      return {
        x: targetX + targetWidth + MARGIN,
        y: yOffset,
      };
  }
};

const calculcateTooltipPosition = (): void => {
  tooltipPosition.value = props.position;
  if (!tooltipEl.value || !targetEl.value) return;

  const options = {
    tooltipWidth: tooltipEl.value.clientWidth,
    tooltipHeight: tooltipEl.value.clientHeight,
    targetWidth: targetEl.value.clientWidth,
    targetHeight: targetEl.value.clientHeight,
    targetX: targetEl.value.getBoundingClientRect().x,
    targetY: targetEl.value.getBoundingClientRect().y,
    position: tooltipPosition.value,
  };

  let position: WindowPosition = buildPosition(options);
  let top = position.y;
  let left = position.x;

  const availablePositions = findAvailability(options);
  if (!availablePositions[tooltipPosition.value]) {
    const positions = Object.keys(availablePositions) as Position[];
    const bestPosition = positions.find((key) => availablePositions[key]);
    tooltipPosition.value = bestPosition || tooltipPosition.value;

    position = buildPosition({ ...options, position: tooltipPosition.value });
    top = position.y;
    left = position.x;
  }

  tooltipEl.value.style.position = 'absolute';
  tooltipEl.value.style.top = `${top}px`;
  tooltipEl.value.style.left = `${left}px`;
};

const hide = (): void => {
  setTimeout(() => {
    visible.value = false;
    if (tooltipEl.value) tooltipEl.value.style.display = 'none';
    if (targetEl.value) targetEl.value.classList.remove('-active');
    calculcateTooltipPosition();
  }, 100);
};
const show = (): void => {
  setTimeout(() => {
    visible.value = true;
    if (tooltipEl.value) tooltipEl.value.style.display = 'block';
    if (targetEl.value) targetEl.value.classList.add('-active');
    calculcateTooltipPosition();
  }, 100);
};

const toggle = (): void => {
  if (!visible.value) show();
  else hide();
};

onMounted(() => {
  targetEl.value = document.getElementById(targetId);
  tooltipEl.value = document.getElementById(tooltipId);
  calculcateTooltipPosition();

  window.addEventListener('scroll', calculcateTooltipPosition);
  window.addEventListener('resize', calculcateTooltipPosition);
});

onUnmounted(() => {
  window.removeEventListener('scroll', calculcateTooltipPosition);
  window.removeEventListener('resize', calculcateTooltipPosition);
});
</script>
<template>
  <div id="tooltips">
    <div
      :id="tooltipId"
      v-on-click-outside="hide"
      :class="`c-tooltip -${tooltipPosition} -${background} w-max font-normal`"
      role="tooltip"
    >
      <span class="c-tooltip__content | t-cms">
        <!-- @slot Takes the content for the tooltip -->
        <slot />
      </span>
      <span class="c-tooltip__arrow" />
    </div>
  </div>

  <!-- @slot Takes the target for the tooltip -->
  <slot
    name="target"
    v-bind="{ id: targetId, describedBy: tooltipId, toggle }"
  />
</template>
